<template>
	<div class="onlineRetailers">
		<applyFrom @close="close" v-if="applyFromshow"></applyFrom>
		<div class="banner">
			<div class="banner-body flex">
				<div class="banner-body-title flex">
					<div>
						<div class="banner-body-title-top flex">
							<span class="banner-body-title-top-t">力瀚电商系统</span>
							<div class="banner-body-title-top-b">会员制</div>
						</div>
						<div class="banner-body-title-cent">会员制分销+拼团+砍价 快速传播 拉新引流</div>
						<div class="banner-body-title-lab">小程序+微商城，移动营销全覆盖， 全新版本重磅登场</div>
						<div class="banner-body-title-but" @click="applyFromshow = true">获取方案</div>
					</div>
				</div>
				<div class="banner-body-img flex">
					<img style="width: 1.70rem;position: absolute;left: 0;z-index: 11;bottom: 0;"
						src="../assets/img/onlineRetailers-banner2.png">
					<img style="width: 1.90rem;position: absolute;left: 1.10rem;bottom: -0.15rem;"
						src="../assets/img/onlineRetailers-banner1.png">

				</div>
			</div>
			<!-- <img style="width: 100%;height: 100%;" src="../assets/img/Journalismbanner.png"> -->
		</div>

		<!-- <div class="trend">
			<div class="trend-tit">
				<span class="trend-tit-tit">电商-未来5-10年电商发展趋势</span><br/>
				<span class="trend-tit-lab">2019什么电商模式最火，当然要属“电商”，而电商的核心经营理念是，经营用户，力瀚为企业提供专业的电商解决方案</span>
			</div>
			<div class="trend-imgbox flex">
				<div class="trend-imgbox-box">
					<img src="../assets/img/onlineRetailers-trend1.png" >
					<div class="trend-imgbox-box-tit">一分钟了解什么是电商</div>
				</div>
				<div class="trend-imgbox-box">
					<img src="../assets/img/onlineRetailers-trend2.png" >
					<div class="trend-imgbox-box-tit">力瀚会员制分享模式介绍</div>
				</div>
			</div>
		</div> -->

		<div class="pattern">
			<div class="pattern-tit">力瀚带你玩转主流的电商模式</div>
			<div class="pattern-imgbox flex">
				<div class="pattern-imgbox-cell1">
					<div class="imgbox-cell-tit">拼团砍价模式</div>
					<div class="imgbox-cell-lab">拼多多崛起后，带动了主流电商平台也开启 了拼团砍价模式，通过微信的优势，开 启多人拼团，结合全民砍价，“砍一刀”快 速传播</div>
				</div>
				<div class="pattern-imgbox-cell2">
					<div class="imgbox-cell-tit">会员制分销模式</div>
					<div class="imgbox-cell-lab">云集模式的成功，将分销与会员的电 商模式带来了一个全新的玩法，用户通过 开通会员获得分享权益，推荐更多会员享 受提成</div>
				</div>
				<div class="pattern-imgbox-cell3">
					<div class="imgbox-cell-tit">内容电商模式</div>
					<div class="imgbox-cell-lab">通过长文章的内容模式，引入商品的介绍， 分享使用心得，同时关联商品购买地址， 带动商品销售</div>
				</div>
			</div>
		</div>

		<div class="function">
			<div class="function-tit">
				<span class="function-tit-tit">会员制电商分销裂变功能</span><br />
				<span class="function-tit-lab">基于微信的生态圈用户流量，让平台更快的传播获客，吸引海量用户资源</span>
			</div>

			<div class="function-Cell flex">
				<div class="Cell-bor flex">
					<div class="Cell-bor-cell">
						<img style="width: 0.48rem;padding: 0.10rem 0;" src="../assets/img/onlineRetailers-1icon.png">
						<div class="Cell-bor-cell-tit">多人拼团</div>
						<div class="Cell-bor-cell-lab">基于微信的拼团，开 团后邀请好友成团</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.42rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-2icon.png">
						<div class="Cell-bor-cell-tit">砍价活动</div>
						<div class="Cell-bor-cell-lab">分享“帮忙砍一刀”微信内 互动交流砍价</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.51rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-5icon.png">
						<div class="Cell-bor-cell-tit">专属推广海报</div>
						<div class="Cell-bor-cell-lab">会员邀请好友时自动 生成精美推广海报</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.37rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-6icon.png">
						<div class="Cell-bor-cell-tit">平台财富排行榜</div>
						<div class="Cell-bor-cell-lab">分享“帮忙砍一刀”微信内 互动交流砍价</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.50rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-9icon.png">
						<div class="Cell-bor-cell-tit">公众号营销</div>
						<div class="Cell-bor-cell-lab">吸引用户关注，定期推送 平台活动消息</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.46rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-10icon.png">
						<div class="Cell-bor-cell-tit">个性会员主页</div>
						<div class="Cell-bor-cell-lab">成为会员后的个性化会员 中心，彰显身份</div>
					</div>
				</div>
				<div>
					<img style="width: 3.39rem;" src="../assets/img/onlineRetailers-Applet.png">
				</div>
				<div class="Cell-bor flex">
					<div class="Cell-bor-cell">
						<img style="width: 0.46rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-3icon.png">
						<div class="Cell-bor-cell-tit">高级VIP中心</div>
						<div class="Cell-bor-cell-lab">展示高级会员收益明细及 下级会员概况</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.37rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-4icon.png">
						<div class="Cell-bor-cell-tit">会员专属微店</div>
						<div class="Cell-bor-cell-lab">每个会员都有自己的 专属店铺分享商品</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.47rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-7icon.png">
						<div class="Cell-bor-cell-tit">会员粉丝管理</div>
						<div class="Cell-bor-cell-lab">会员可以随时查看下级会员 列表及贡献详情</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.41rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-8icon.png">
						<div class="Cell-bor-cell-tit">赚取佣金明细</div>
						<div class="Cell-bor-cell-lab">查看下级会员每笔订单 是否分成的佣金明细</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.36rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-11icon.png">
						<div class="Cell-bor-cell-tit">商品分享传播</div>
						<div class="Cell-bor-cell-lab">通过社会化分享将商品直 接对外传播</div>
					</div>
					<!-- <div class="Cell-bor-cell">
						<img style="width: 0.42rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-12icon.png">
						<div class="Cell-bor-cell-tit">圈子互动</div>
						<div class="Cell-bor-cell-lab">发布帖子关联商品，打造 内容电商模式</div>
					</div> -->
				</div>
			</div>
		</div>

		<div class="system">
			<div class="system-top">力瀚电商核心体系</div>
			<div class="system-cell flex">
				<div class="system-cell-cell">
					<img style="width: 0.28rem;padding: 0.20rem 0;" src="../assets/img/onlineRetailers-system-1.png">
					<div class="system-cell-cell-tit">会员等级自定义</div>
					<div class="system-cell-cell-lab">店员+平台可以根据自己的销售规则， 定义不同会员等级享受的商品优惠， 促进会员购物消费升级</div>
				</div>
				<div class="system-cell-cell">
					<img style="width: 0.28rem;padding: 0.20rem 0;" src="../assets/img/onlineRetailers-system-2.png">
					<div class="system-cell-cell-tit">升级条件自定义</div>
					<div class="system-cell-cell-lab">通过不同的礼包以及订单返利优惠， 设置VIP会员制的升级条件， 促进发展更多下级会员</div>
				</div>
				<!-- <div class="system-cell-cell">
					<img style="width: 0.28rem;padding: 0.20rem 0;" src="../assets/img/onlineRetailers-system-3.png">
					<div class="system-cell-cell-tit">快速宣传推广</div>
					<div class="system-cell-cell-lab">通过系统功能体系，传播便捷性， 借助微信的10亿用户流量， 扩展平台的海量曝光入口 </div>
				</div> -->
				<div class="system-cell-cell">
					<img style="width: 0.28rem;padding: 0.20rem 0;" src="../assets/img/onlineRetailers-system-4.png">
					<div class="system-cell-cell-tit">下级会员规范管理</div>
					<div class="system-cell-cell-lab">多样化的会员开通流程，成熟的邀请 拉新机制，规范化管理会员的下级 会员，列表式详情查看</div>
				</div>
			</div>
		</div>

		<div class="Marketing">
			<div class="Marketing-tit">
				<span class="Marketing-tit-tit">电商-移动营销全覆盖</span><br />
				<span class="Marketing-tit-lab">力瀚电商版是一款满足电商+移动电商的战略性产品，系统包含APP、微商城、小程序等多个移动终端，并实现数据同步，统一后台</span>
			</div>
		
			<div class="Marketing-imgbox flex">
				<div>
					<img class="Marketing-imgbox-img1" style="max-width: 2.96rem;" src="../assets/img/onlineRetailers-Marketing1.png">
					<div style="padding: 0.10rem 0;line-height: 0.20rem;">
						<div style="font-size: 0.13rem;color: #333;margin-bottom: 0.10rem;">小程序</div>
						<div style="font-size: 0.09rem;color: #999999;">搭建无需下载，用完即走的电商 小程序，抢占第一波红利</div>
					</div>
				</div>
				<div>
					<img class="Marketing-imgbox-img1" style="max-width: 2.96rem;" src="../assets/img/onlineRetailers-Marketing2.png">
					<div style="padding: 0.10rem 0;line-height: 0.20rem;">
						<div style="font-size: 0.13rem;color: #333;margin-bottom: 0.10rem;">APP</div>
						<div style="font-size: 0.09rem;color: #999999;">通过APP聚拢用户，随时推送 消息，提高用户的粘性</div>
					</div>
				</div>
				<div>
					<img class="Marketing-imgbox-img1" style="max-width: 2.96rem;" src="../assets/img/onlineRetailers-Marketing3.png">
					<div style="padding: 0.10rem 0;line-height: 0.20rem;">
						<div style="font-size: 0.13rem;color: #333;margin-bottom: 0.10rem;">微商城</div>
						<div style="font-size: 0.09rem;color: #999999;">玩转微信营销，可分享到外部 平台推广，吸引流量</div>
					</div>
				</div>
			</div>
		</div>

		<div class="introduce flex">
			<div class="introduce-box flex">
				<div class="introduce-box-l">
					<div class="introduce-box-l-tit">无需依赖PC端运行， 更轻更快</div>
					<div class="introduce-box-l-lab">免去PC端的平台部署和维护，专注在移动 电商的运营上，打造针对性业务需求</div>
				</div>
				<img style="width: 5.00rem;" src="../assets/img/onlineRetailers-introduce.png">
			</div>
		</div>

		<div class="examine">
			<div class="examine-box">
				<div class="examine-box-tit">
					<span>手机端商家入驻</span><br />
					<span>便捷招商不用愁</span>
				</div>
				<div class="examine-box-lab">
					力瀚电商版支持在手机端进行商家入驻， 让平台更便捷招商入驻，打造多用户移动商城
				</div>
			</div>
			<img style="width: 100%;" src="../assets/img/onlineRetailers-examine.png">
		</div>

		<div class="programme">
			<div class="programme-tit">支持私有化部署，满足定制开发需求</div>
			<div class="programme-but flex">
				<div class="programme-but-input">打造专属移动商城，力瀚更懂你的需求</div>
				<div class="programme-but-but flex" @click="applyFromshow = true">点击定制</div>
			</div>
		</div>
	</div>
</template>

<script>
	import applyFrom from '@/components/applyFrom.vue';
	export default {
		data() {
			return {
				applyFromshow: false
			}
		},
		components: {
			// Canvas，
			applyFrom
		},

		methods: {
			close() {
				this.applyFromshow = false;
			},
		}
	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width:750px) {
		
		.pattern-imgbox{
			width: 90% !important;
		}
		
		.system-cell{
			width: 95% !important;
			.system-cell-cell{
				width: 2.2rem !important;
				line-height: 0.3rem;
			}
		}
		.banner{
			height: 4rem !important;
			.banner-body-title-but{
				margin-top: 0.2rem !important;
			}
		}
		
		.Marketing-imgbox-img1{
			width: 2.5rem !important;
		}

		.function-Cell {
			width: 100%;

			.Cell-bor-cell {
				width: 1.6rem !important;
			}
		}
		
		.banner-body-title-top-t{
			margin-top: 0.3rem;
			margin-right: 0rem !important;
		}
	}

	.banner {
		width: 100%;
		height: 3.50rem;
		// padding-top: 30px;
		position: relative;
		z-index: 101;

		.banner-body {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;

			// padding: 40px 0;
			.banner-body-img {
				width: 35%;
				height: 100%;
				text-align: left;
				justify-content: initial;
				position: relative;
			}

			.banner-body-title {
				width: 44%;
				height: 100%;
				// flex-direction: column;
				text-align: left;
				// justify-content: right;

				// align-items: initial;
				.banner-body-title-top {
					width: 100%;
					justify-content: inherit;
					margin-right: 0.60rem;

					.banner-body-title-top-t {
						font-size: 0.36rem;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #333333;
						margin-right: 1.60rem;
					}

					.banner-body-title-top-b {
						width: 1.00rem;
						height: 0.40rem;
						font-size: 0.22rem;
						text-align: center;
						line-height: 0.40rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						background-image: url(../assets/img/onlineRetailers-but.png);
						background-repeat: no-repeat;
						background-size: 100%;
					}
				}

				.banner-body-title-cent {
					width: 90%;
					height: 0.44rem;
					margin-top: 0.20rem;
					background-image: url(../assets/img/onlineRetailerstop-back.png);
					background-size: 100%;
					font-size: 0.22rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 0.44rem;
					padding-left: 0.20rem;
				}

				.banner-body-title-lab {
					font-size: 0.17rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #666666;
					padding: 0.20rem 0;
				}

				.banner-body-title-but {
					width: 2.00rem;
					height: 0.50rem;
					background-image: url(../assets/img/onlineRetailerstop-but.png);
					background-repeat: no-repeat;
					background-size: 100%;
					font-size: 0.24rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
					line-height: 0.50rem;
					margin-top: 0.40rem;
				}
			}
		}

		// background-image: url(../assets/img/Journalismbanner.png);
	}

	.trend {
		width: 100%;
		height: 4.46rem;
		background-image: url(../assets/img/onlineRetailers-bj-2.png);
		background-size: 100%;
		background-repeat: no-repeat;
		z-index: 12;
		position: relative;
		padding: 0.50rem 0;

		.trend-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.trend-tit-tit {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
			}

			.trend-tit-lab {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
			}
		}

		.trend-imgbox {
			width: 50%;
			margin: 0 auto;
			margin-top: 0.40rem;
			justify-content: space-between;

			.trend-imgbox-box-tit {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				padding: 0.30rem 0;
			}
		}
	}

	.pattern {
		width: 100%;
		padding: 0.50rem 0;
		background-color: #f7f7f7;

		.pattern-tit {
			width: 100%;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333333;
		}

		.pattern-imgbox {
			width: 50%;
			margin: 0 auto;
			justify-content: space-between;
			padding: 0.60rem 0;

			.pattern-imgbox-cell1 {
				width: 2.25rem;
				height: 2.65rem;
				background-image: url(../assets/img/onlineRetailers-2f-bg1.png);
				background-repeat: no-repeat;
				background-size: 100%;
			}

			.pattern-imgbox-cell2 {
				width: 2.25rem;
				height: 2.65rem;
				background-image: url(../assets/img/onlineRetailers-2f-bg2.png);
				background-repeat: no-repeat;
				background-size: 100%;
			}

			.pattern-imgbox-cell3 {
				width: 2.25rem;
				height: 2.65rem;
				background-image: url(../assets/img/onlineRetailers-2f-bg3.png);
				background-repeat: no-repeat;
				background-size: 100%;
			}

			.imgbox-cell-tit {
				width: 90%;
				margin: 0 auto;
				font-size: 0.17rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				padding: 0.15rem 0;
			}

			.imgbox-cell-lab {
				width: 90%;
				margin: 0 auto;
				font-size: 0.10rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
			}
		}
	}

	.function {
		width: 100%;
		// background-color: #f7f7f7;
		padding: 0.60rem 0;

		.function-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.function-tit-tit {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}

			.function-tit-lab {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
			}
		}

		.function-Cell {
			// width: 50%;
			margin: 0 auto;
			margin-top: 0.40rem;
		
			.Cell-bor {
				width: 4.00rem;
				// height: 6.80rem;
				flex-wrap: wrap;
				// align-items: initial;
				justify-content: space-between;
		
				.Cell-bor-cell {
					width: 1.80rem;
					// height: 1.60rem;
					margin-right: 0.1rem;
					margin-bottom: 0.1rem;
					// padding: 10px 0;
					line-height: 0.2rem;
					background-color: #fff;
					box-shadow: 0px 2px 9px 1px rgba(168, 168, 168, 0.2);
		
					.Cell-bor-cell-tit {
						font-size: 0.20rem;
						padding: 0.05rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #333333;
						margin-bottom: 0.10rem;
						margin-top: 0.2rem;
					}
		
					.Cell-bor-cell-lab {
						font-size: 0.14rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						padding: 0 0.05rem;
						color: #999999;
					}
				}
			}
		}
	}
	
	.system {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;

		.system-top {
			width: 100%;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333333;
		}

		.system-cell {
			width: 60%;
			margin: 0 auto;
			justify-content: space-between;
			margin-top: 0.60rem;

			.system-cell-cell {
				width: 2.60rem;
				height: 2.50rem;
				box-shadow: 0px 2px 9px 1px rgba(168, 168, 168, 0.2);
				background-color: #fff;

				.system-cell-cell-tit {
					font-size: 0.13rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					padding-bottom: 0.30rem;
					margin-top: 0.20rem;
				}

				.system-cell-cell-lab {
					padding: 0 0.20rem;
					font-size: 0.09rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #999999;
				}
			}
		}
	}

	.Marketing {
		width: 100%;
		// background-color: #f7f7f7;
		padding: 0.60rem 0;
	
		.Marketing-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;
	
			.Marketing-tit-tit {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}
	
			.Marketing-tit-lab {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
			}
		}
	
		.Marketing-imgbox {
			width: 50%;
			margin: 0 auto;
			margin-top: 0.60rem;
			// justify-content: space-between;
		}
	}

	.introduce {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;

		.introduce-box {

			// width: 50%;
			.introduce-box-l {
				flex: 1;
				height: 100%;
				padding-right: 0.40rem;

				.introduce-box-l-tit {
					font-size: 0.27rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
				}

				.introduce-box-l-lab {
					font-size: 0.12rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #666666;
					margin-top: 0.30rem;
				}
			}
		}
	}

	.examine {
		width: 100%;
		position: relative;

		.examine-box {
			width: 40%;
			height: 1.50rem;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -0.75rem;
			text-align: left;

			.examine-box-tit {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.39rem;
			}

			.examine-box-lab {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-top: 0.50rem;
			}
		}
	}

	.programme {
		width: 100%;
		height: 2.00rem;
		background-image: url(../assets/img/dg-bg.png);
		background-repeat: no-repeat;

		.programme-tit {
			width: 100%;
			text-align: center;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			padding: 0.40rem 0;
		}

		.programme-but {
			// width: 40%;
			margin: 0 auto;

			.programme-but-input {
				// width: 320px;
				padding: 0 0.20rem;
				height: 0.45rem;
				border: 1px solid #ccc;
				font-size: 0.15rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.45rem;
			}

			.programme-but-but {
				width: 1.50rem;
				height: 0.45rem;
				font-size: 0.20rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 0.60rem;
				border-radius: 5px;
				background: linear-gradient(90deg, #2E89F9, #06D8EE);
			}
		}
	}
</style>
